.onfido-sdk-ui-Welcome-balanceContainer {
	display: none !important;
}

.onfido-sdk-ui-Theme-scrollableContent:has(.onfido-sdk-ui-Welcome-container) {
	justify-content: center !important;
	margin-bottom: 10rem !important;
}
.onfido-sdk-ui-Welcome-container {
	flex-grow: 0 !important;
	height: auto !important;
}
